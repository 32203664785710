import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/[[other_pages]]/account": [~4,[2]],
		"/[[other_pages]]/account/addresses": [5,[2]],
		"/[[other_pages]]/account/orders": [~6,[2]],
		"/[[other_pages]]/account/payments": [~7,[2]],
		"/[[other_pages]]/apostoles-epistrofes": [8],
		"/[[other_pages]]/basket": [~9],
		"/[[other_pages]]/certificates": [10],
		"/[[other_pages]]/checkout": [~11],
		"/[[other_pages]]/contact": [12],
		"/eidikotites": [27],
		"/[[other_pages]]/etairia": [13],
		"/farmakeia": [28],
		"/ktiniatreia": [~29],
		"/[[other_pages]]/login": [14],
		"/[[other_pages]]/logout": [~15],
		"/[[other_pages]]/lost-password": [16],
		"/medil-dental": [~30],
		"/microbiology": [31],
		"/[[other_pages]]/omada": [17],
		"/[[other_pages]]/paragogi": [18],
		"/[[other_pages]]/politiki-aporitou": [19],
		"/[[other_pages]]/politiki-poiotitas": [20],
		"/products/[products_id]/[products_slug]": [~33],
		"/p/[product_id]/[galaxy_id]/[product_slug]": [~32],
		"/[[other_pages]]/reset-password/[token]": [21],
		"/[[other_pages]]/search": [~22],
		"/sentry-example": [34],
		"/shop": [~35],
		"/shop/[category_id]/[category_slug]": [~36],
		"/[[other_pages]]/signup": [~23],
		"/[[other_pages]]/topothesia": [24],
		"/[[other_pages]]/tropoi-pliromis": [25],
		"/[[other_pages]]/verification/[jwt]": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';